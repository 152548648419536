<template>
  <div class="registration-step-1-block">
    <div class="page-title"><h2>{{ $lang.app.organization_check }}</h2></div>
    <div v-if="!result" class="check-block-container">
      <h4 class="hint-title">{{ $lang.app.organization_check_instruction }}</h4>
      <div class="reg-step-form">
        <Form @submit="submit" :validation-schema="schema">
          <div class="reg-step-form-item">
            <label class="recovery-label">{{ $lang.app.pin }} <i>*</i></label>
            <div class="reg-step-1-select-container">
              <Field name="pin" type="text" autocomplete="off"
                     v-maska="'##############'"
                     v-model="checkForm.pin"/>
              <div class="tooltip-target">
                <i class="bi-question-circle"></i>
                <div class="tooltip-content">
                  <p>{{ $lang.app.id_card_des }}</p>
                  <p>{{ $lang.app.id_card_example }}</p>
                  <div class="passport-images">
                    <div class="passport-image">
                      <img src="../assets/images/pin-2017.png" alt="">
                      <span>{{ $lang.app.id_card }}</span>
                    </div>
                    <div class="passport-image">
                      <img src="../assets/images/pin-2004.png" alt="">
                      <span>{{ $lang.app.passport }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <ErrorMessage name="pin" class="recovery-label-error" />
          </div>
          <div class="reg-step-btn">
            <button type="submit">{{ $lang.app.check }}</button>
          </div>
        </Form>
      </div>
    </div>

    <div v-if="result" class="check-results-block-container">
      <div v-for="organization in result" :key="organization.id"
           class="organization-check-results application-enroll">
        <i class="bi-check-all"></i>
        <h4>{{ $lang.app.organization }}: {{ $lang.$translate({ru: organization.nameRU, kg: organization.nameKG }) }}</h4>
        <h4>{{ $lang.app.address }}: {{ organization.address }}</h4>
      </div>
      <div class="check-results-btn"><a href @click.prevent="result = null">{{ $lang.app.back }}</a></div>
    </div>

  </div>
</template>
<script>
import { maska } from 'maska'
import { Field, Form, ErrorMessage } from 'vee-validate';
import * as yup from "yup";

export default {
  name: 'OrganizationCheck',
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  directives: { maska },
  data() {
    return {
      checkForm: {
        type: 'SCHOOL',
        pin: '',
        recaptchaToken: null,
      },
      schema: yup.object({
        pin: yup.string().required(),
      }),
      result: null,
    }
  },
  methods: {
    async submit(values, actions) {
      this.$store.commit('startLoading')

      await this.$recaptchaLoaded()
      this.checkForm.recaptchaToken = await this.$recaptcha('login')

      this.$axios.post(`/organization/search/nearest`, this.checkForm).then(({data}) => {
        this.result = data
      }).catch(error => {
        if(error.response.status === 400) {
          actions.setErrors(error.response.data.errors);
        }
        if(error.response.status === 406) {
          if(error.response.data.message === 'organization_border_exist') {
            this.$store.commit('showOrganizationNotFoundErrorModal')
          }
          if(error.response.data.message === 'applicant_address_not_found_in_darek_register') {
            this.$store.commit('showAddressNotFoundErrorModal')
          }
        }
      }).finally(() => {
        this.$store.commit('stopLoading');
      })
    }
  }
}
</script>
<style scoped>
@import '../assets/styles/form.css';
@import '../assets/styles/title.css';
.check-block-container {
  width: 40%;
  margin: 0 auto;
  padding: 55px 0 45px;
  border-bottom: 1px solid #d0d0d0;
}
@media screen and (max-width: 1600px) {
  .check-block-container {
    width: 50%;
  }
}
@media screen and (max-width: 1200px) {
  .check-block-container {
    width: 60%;
    padding: 40px 0 50px;
  }
}
@media screen and (max-width: 767px) {
  .check-block-container {
    width: calc(100% - 30px);
    padding: 30px 0 35px;
  }
}

.organization-check-results {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0;
  color: #4c9ac7;
  max-width: 600px;
  margin: 0 auto;
  text-align: center;
}
.organization-check-results.application-not-found {
  color: darkorange;
}
.organization-check-results.application-enroll {
  color: mediumseagreen;
}
.organization-check-results.application-rejected {
  color: orangered;
}
.organization-check-results i {
  font-size: 100px;
  display: inline-block;
  margin-bottom: 20px;
}
.organization-check-results h4 {
  font-size: 24px;
  margin: 0 0 15px 0;
  font-weight: bold;
}
.check-results-btn {
  padding: 40px 0;
  display: flex;
  justify-content: center;
}
.check-results-btn a {
  width: 16.6666%;
  display: inline-block;
  height: 55px;
  line-height: 55px;
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  font-weight: bold;
  padding: 0;
  transition: 0.5s;
  text-decoration: none;
  font-size: 18px;
  background: linear-gradient(to bottom, #efefef 0%, #e2e2e2 100%);
  border: 1px solid #d0d0d0;
  color: #4c9ac7;
}
@media screen and (max-width: 1600px) {
  .check-results-btn a {
    width: 25%;
  }
}
@media screen and (max-width: 1200px) {
  .organization-check-results i {
    margin-bottom: 15px;
  }
  .organization-check-results h4 {
    font-size: 18px;
  }
  .check-results-btn a {
    width: 33.3333%;
  }
}
@media screen and (max-width: 767px) {
  .organization-check-results {
    padding: 15px 0 25px;
  }
  .organization-check-results i {
    margin-bottom: 10px;
  }
  .check-results-btn {
    padding: 30px 15px;
  }
  .check-results-btn a {
    width: 100%;
  }
}
@media screen and (max-width: 479px) {
  .organization-check-results i {
    font-size: 32px;
  }
}
</style>